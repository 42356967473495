import maplibregl from 'maplibre-gl';
import {project} from "maplibre-gl/src/symbol/projection";

const netherlandsBoundingBox = [
    [0.5, 49.0], // Adjusted southwest coordinates
    [10.0, 55.0]  // Adjusted northeast coordinates
];


// const mapStyle = '/map/mapstyle.json';
const mapStyle = 'https://maps2.nbo.nl/styles/xitres-bright-light/style.json';

export default function (Alpine) {
    Alpine.data('maps', (options) => ({
        registrations: window.projectRegistrations ?? [],
        maps: null,
        projects: [],
        customMap: false,
        fitBoundsProjects: [],
        projectsGeoJson: null,
        fitBoundsGeoJson: null,
        hoveredFeaturePid: '',
        hoveredCardPid: '',
        clusterRadius: options.clusterRadius ?? 50,
        compactAttribution: options.compactAttribution ?? null,

        fitBounds() {
            if (null !== this.fitBoundsGeoJson
                && this.fitBoundsGeoJson.features.length !== 0) {
                const padding = this.calculatePadding(this.projects);

                if (this.fitBoundsGeoJson.features.length === 1) {
                    this.maps.fitBounds(this.bbox(this.fitBoundsGeoJson), {
                        padding: padding, zoom: 15
                    });
                } else {
                    this.maps.fitBounds(this.bbox(this.fitBoundsGeoJson), {
                        padding: padding
                    });
                }
            } else if(Object.keys(this.$wire.locationCoordinates).length){
                this.maps.flyTo({
                    center: [this.$wire.locationCoordinates.lng, this.$wire.locationCoordinates.lat], // [longitude, latitude]
                    zoom: ['town', 'neighbourhood'].includes(this.$wire.activeLocation['locationType']) ? 12 : 11
                });
            }
        },

        checkAndHidePoints() {
            if (undefined !== this.fitBoundsProjects && this.fitBoundsProjects.length === 0) {
                this.maps.setLayoutProperty('clusters', 'visibility', 'none');
                this.maps.setLayoutProperty('cluster-count', 'visibility', 'none');
                this.maps.setLayoutProperty('unclustered-point', 'visibility', 'none');
                this.maps.setLayoutProperty('unclustered-point-inner-circle-style', 'visibility', 'none');
                this.maps.setLayoutProperty('registered-projects-text', 'visibility', 'none');
                this.maps.fitBounds(netherlandsBoundingBox);
            } else {
                this.maps.setLayoutProperty('clusters', 'visibility', 'visible');
                this.maps.setLayoutProperty('cluster-count', 'visibility', 'visible');
                this.maps.setLayoutProperty('unclustered-point', 'visibility', 'visible');
                this.maps.setLayoutProperty('unclustered-point-inner-circle-style', 'visibility', 'visible');
                this.maps.setLayoutProperty('registered-projects-text', 'visibility', 'visible');
            }
        },

        init() {
            this.maps = new maplibregl.Map({
                container: this.$el,
                style: mapStyle,
                center: [5.6, 52.3],
                pitchWithRotate: false,
                zoom: 8,
                maxBounds: netherlandsBoundingBox,
                attributionControl: (this.compactAttribution === null),
            })

            if (this.compactAttribution !== null) {
                this.maps.addControl(new maplibregl.AttributionControl({
                    compact: this.compactAttribution
                }));
            }

            // Create a new Promise that resolves when the map is loaded
            const mapLoaded = new Promise((resolve, reject) => {
                this.maps.on('load', () => {
                    resolve();
                });
            });

            // Wait for the mapLoaded promise to resolve before calling loadStyles
            mapLoaded.then(() => {
                this.maps.resize()

                // load the projects first time
                this.projects = this.$wire.projects
                this.fitBoundsProjects = this.$wire.fitBoundsProjects;

                // in profile, we only want to show matched projects
                if (this.$wire.customMap) {
                    this.fitBoundsProjects = this.$wire.projects;
                }

                this.projectsGeoJson = this.projectsToGeoJSON(this.projects);
                this.fitBoundsGeoJson = this.projectsToGeoJSON(this.fitBoundsProjects);

                this.loadData();
                this.loadStyles();
                this.mapEvents();

                this.checkAndHidePoints();

                document.addEventListener('updateMapBounds', () => {
                    this.fitBounds();
                });
                this.fitBounds();


                // listener for map update
                this.$wire.on('updateMap', (data) => {
                    if (Array.isArray(data) && data.length === 0) {
                        data = undefined;
                    }

                    this.projects = data !== undefined ? data['projects'] : this.$wire.projects;
                    this.fitBoundsProjects = data !== undefined ? data['fitBoundsProjects'] : this.$wire.fitBoundsProjects;

                    if (this.$wire.customMap) {
                        this.fitBoundsProjects = this.projects;
                    }

                    this.checkAndHidePoints();

                    this.projectsGeoJson = this.projectsToGeoJSON(this.projects);
                    this.fitBoundsGeoJson = this.projectsToGeoJSON(this.fitBoundsProjects);

                    this.fitBounds();

                    let source = this.maps.getSource('projects');
                    if (source) {
                        source.setData(this.projectsGeoJson);
                    }
                });
            });

        },
        mapEvents() {

            // Mouse events for cluster layer
            // ------------------------------------------------- //
            this.maps.on('mouseenter', 'clusters', function () {
                this.getCanvas().style.cursor = 'pointer';
            });
            this.maps.on('mouseleave', 'clusters', function () {
                this.getCanvas().style.cursor = '';
            });

            // Mouse events for unclustered layer
            // ------------------------------------------------- //
            this.maps.on('mouseenter', 'unclustered-point', (e) => {
                this.maps.getCanvas().style.cursor = 'pointer';

                if (e.features.length > 0) {
                    this.hoveredFeaturePid = e.features[0].properties.ulid;

                    // Add class to all element with corresponding data-pid attribute
                    document.querySelectorAll(`[data-pid="${this.hoveredFeaturePid}"]`).forEach(el => {
                        el.classList.add('border-orange');
                        el.classList.add('shadow-lg');
                    });

                    // If old highlight on map marker, remove
                    if (this.hoveredFeaturePid) {
                        this.maps.setFeatureState(
                            {source: 'projects', id: this.hoveredFeaturePid},
                            {hover: false}
                        );
                    }

                    // Add hovered state on new marker
                    this.maps.setFeatureState(
                        {source: 'projects', id: this.hoveredFeaturePid},
                        {hover: true}
                    );
                }
            });
            this.maps.on('mouseleave', 'unclustered-point', (e) => {
                this.maps.getCanvas().style.cursor = '';

                // Remove style(s)
                document.querySelectorAll(`[data-pid="${this.hoveredFeaturePid}"]`).forEach(el => {
                    el.classList.remove('shadow-lg');
                    el.classList.remove('border-orange');
                });

                // If old highlight on map marker, remove
                if (this.hoveredFeaturePid) {
                    this.maps.setFeatureState(
                        {source: 'projects', id: this.hoveredFeaturePid},
                        {hover: false}
                    );
                }

                this.hoveredFeaturePid = '';
            });

            // Zoom to features on cluster click
            this.maps.on('click', 'clusters', (e) => {
                let features = this.maps.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });

                let clusterId = features[0].properties.cluster_id;
                let pointCount = features[0].properties.point_count;

                let padding = 200;

                let width = this.$el.clientWidth;

                // if mobile
                if (width < 768) {
                    padding = 20;
                }

                this.maps.getSource('projects').getClusterLeaves(clusterId, pointCount, 0)
                    .then((features) => {
                        this.maps.fitBounds(this.getBboxFromFeatureList(features), {
                            padding: padding
                        });
                    })
            });


            // Mouse events for cards
            // ------------------------------------------------- //
            document.querySelectorAll(`[data-pid]`).forEach(el => {
                el.addEventListener('mouseover', (event) => {
                    let oldPid = this.hoveredCardPid;
                    this.hoveredCardPid = parseInt(el.dataset.pid);

                    // Remove old hoveredCardPid if there's one
                    if (oldPid && oldPid !== this.hoveredCardPid) {
                        if (this.hoveredFeaturePid) {
                            this.maps.setFeatureState(
                                {source: 'projects', id: oldPid},
                                {hover: false}
                            );
                        }
                    }

                    // Set feature state hover to true for styling
                    this.maps.setFeatureState(
                        {source: 'projects', id: this.hoveredCardPid},
                        {hover: true}
                    );
                });

                el.addEventListener('mouseout', (event) => {
                    // Set feature state hover to false
                    this.maps.setFeatureState(
                        {source: 'projects', id: this.hoveredCardPid},
                        {hover: false}
                    );

                    this.hoveredCardPid = ''; // Set hovered card pid back to nothing
                });
            });

            // When a click event occurs on a feature in the unclustered layer, open a popup at the
            // location of the feature, with description HTML from its properties.
            this.maps.on('click', 'unclustered-point', (e) => {
                if (true === this.$wire.clickableDot) {
                    // Ensure the click event has features before continuing
                    if (!e.features || e.features.length === 0) {
                        console.error("No features found in event data.");
                        return;
                    }

                    let properties = e.features[0].properties;
                    let coordinates = e.features[0].geometry.coordinates.slice();

                    // Define the popup and set the skeleton HTML directly
                    let popup = new maplibregl.Popup({
                        className: 'map-project-popup',
                        keepInView: true // Keeps popup in view even when close to the map edges
                    }).setLngLat(coordinates)
                        .setHTML(`
              <div class="w-[276px] min-h-[382px]" id="popup-content">
                  <div class="bg-gray-100 rounded-md animate-pulse" style="width: 275px; height: 190px;"></div>
                  <div class="grid grid-cols-1 gap-2 text-center mx-auto mt-4 px-4">
                      <div class="bg-gray-100 rounded-md animate-pulse" style="width: 200px; height: 10px;"></div>
                      <div class="bg-gray-100 rounded-md animate-pulse" style="width: 100px; height: 10px;"></div>
                      <div class="bg-gray-100 rounded-md animate-pulse" style="width: 50px; height: 5px;"></div>
                  </div>
                  <div class="grid grid-cols-2 gap-2 text-center mx-auto mt-4 px-4">
                      <div class="bg-gray-100 rounded-md animate-pulse h-12"></div>
                      <div class="bg-gray-100 rounded-md animate-pulse h-12"></div>
                  </div>
                  <div class="grid grid-cols-1 gap-2 text-center mx-auto mt-8 px-4">
                      <div class="bg-gray-100 rounded-md animate-pulse" style="width: 200px; height: 10px;"></div>
                  </div>
              </div>
          `) // Set the skeleton HTML directly
                        .addTo(this.maps);

                    // Adjust longitude if necessary for popup location
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    // Set the Y-offset based on whether the point is registered
                    let yOffset = properties.isRegistered ? 14 : 7;
                    let offsets = {
                        'top': [0, yOffset],
                        'bottom': [0, -yOffset],
                        'left': [yOffset, 0],
                        'right': [-yOffset, 0],
                        'top-left': [yOffset, yOffset],
                        'top-right': [-yOffset, yOffset],
                        'bottom-left': [yOffset, -yOffset],
                        'bottom-right': [-yOffset, -yOffset]
                    };

                    popup.setOffset(offsets); // Apply offset for skeleton positioning

                    // Fetch and replace skeleton with actual content
                    this.$wire.getView(properties.ulid).then(viewHtml => {
                        document.getElementById('popup-content').innerHTML = viewHtml; // Replace skeleton with actual content
                        popup.setOffset(offsets); // Reapply offset for final content
                    }).catch(error => {
                        console.error("Error loading content:", error);
                        document.getElementById('popup-content').innerHTML = "<p>Error loading content</p>";
                    });
                }
            });
        },
        loadData() {
            this.projectsGeoJson = this.projectsToGeoJSON(this.projects);

            this.maps.addSource('projects', {
                type: 'geojson',
                data: this.projectsGeoJson,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: this.clusterRadius,   // Radius of each cluster when clustering points (defaults to 50)
            });

            // we have a single project, so we want to zoom in
            if (this.projects.length === 1) {
                let coordinates = this.projects[0]['coordinates'];
                this.maps.setCenter([
                    coordinates['lng'],
                    coordinates['lat'],
                ]);
            }
        },
        getBboxFromFeatureList(list) {
            return [
                Math.min(...list.map(x => x['properties']['coordinates']['lng'])),
                Math.min(...list.map(x => x['properties']['coordinates']['lat'])),
                Math.max(...list.map(x => x['properties']['coordinates']['lng'])),
                Math.max(...list.map(x => x['properties']['coordinates']['lat']))
            ];
        },
        /**
         * Load the map styles
         */
        loadStyles() {
            // Add zoom and rotation controls to the map.
            this.maps.addControl(new maplibregl.NavigationControl({showCompass: false}), 'bottom-right');

            // Cluster layer
            // ------------------------------------------------- //
            this.maps.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'projects',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': '#c93c4e',
                    'circle-stroke-color': '#c93c4e',
                    'circle-stroke-width': 6,
                    'circle-stroke-opacity': .5,
                    'circle-radius': 16
                }
            });

            // Cluster layer number count
            // ------------------------------------------------- //
            this.maps.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'projects',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ["Noto Sans Bold"],
                    'text-size': 14,
                },
                paint: {
                    'text-color': '#fff'
                }
            });

            // Unclustered layer
            // ------------------------------------------------- //
            // Outer rings
            this.maps.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'projects',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-radius': [
                        'case',
                        ['==', ['get', 'isRegistered'], true],
                        20, // Bigger circle for registered projects
                        10
                    ],
                    'circle-color': [
                        'case',
                        ['==', ['get', 'isRegistered'], true],
                        '#5CB900', // Green color for registered projects
                        [
                            'case',
                            ['==', ['get', 'projectCompleted'], true],
                            '#808080',
                            [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#C71066',
                                '#F8AD47'
                            ]
                        ]
                    ],
                    'circle-stroke-width': 5,
                    'circle-stroke-opacity': 0.3,
                    'circle-stroke-color': [
                        'case',
                        ['==', ['get', 'isRegistered'], true],
                        '#5CB900', // Darker green for the stroke of registered projects
                        [
                            'case',
                            ['==', ['get', 'projectCompleted'], true],
                            '#808080',
                            [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#C71066',
                                '#F8AD47'
                            ]
                        ]
                    ]
                }
            });

            // Inner point of the circle
            this.maps.addLayer({
                id: 'unclustered-point-inner-circle-style',
                type: 'circle',
                source: 'projects',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-radius': 5,
                    'circle-color': [
                        'case',
                        ['==', ['get', 'isRegistered'], true],
                        '#5CB900', // Green color for registered projects
                        '#fff'
                    ]
                }
            });

            this.maps.loadImage('/img/icons/favorite_heart.png', (error, image) => {
                if (error) throw error;
                this.maps.addImage('registered-project-icon', image);
            });

            // Add a symbol layer for the text on top of the circles
            this.maps.addLayer({
                id: 'registered-projects-text',
                type: 'symbol',
                source: 'projects',
                filter: ['==', ['get', 'isRegistered'], true],
                layout: {
                    'icon-image': 'registered-project-icon', // Use the ID of the loaded image
                    'icon-size': 0.4, // Adjust the size as needed
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-offset': [0, 5]
                },
                paint: {
                    'text-color': '#fff'
                }
            });
        },
        /**
         * Convert JSON projects data to GeoJSON for mapLibre
         */
        projectsToGeoJSON(input) {
            let geoJSON = {
                'type': 'FeatureCollection',
                'features': []
            };

            if(undefined === input) {
                return geoJSON;
            }
            if (typeof input === 'string') {
                input = JSON.parse(input);
            }

            input.forEach(project => {

                // Check if the project is registered
                let isRegistered = this.registrations.some(registration => registration === project.ulid);

                // Check is project is actually completed
                let projectCompleted = ['VERKOCHT', 'VERHUURD', 'AFGEROND'].includes(project.phase)

                // Bounding box Netherlands front-end filter
                if (project.coordinates.lng > 3.231080 && project.coordinates.lng < 7.274048 && project.coordinates.lat > 50.727285 && project.coordinates.lat < 53.565390) {
                    let projectFeature = {
                        'type': 'Feature',
                        'id': project.ulid,
                        'properties': {
                            ...project,
                            isRegistered: isRegistered,
                            projectCompleted: projectCompleted
                        },
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [project.coordinates.lng, project.coordinates.lat]
                        }
                    }

                    geoJSON['features'].push(projectFeature);
                }
            });

            return geoJSON;
        },
        /**
         * Convert all projects to boundingbox
         * @param data
         * @returns {number[]}
         */
        bbox(data) {
            let coords = Object.values(data.features).map(object => {
                return object.geometry.coordinates;
            });


            return [
                Math.min(...coords.map(coord => coord[0])),
                Math.min(...coords.map(coord => coord[1])),
                Math.max(...coords.map(coord => coord[0])),
                Math.max(...coords.map(coord => coord[1]))
            ];
        },

        calculatePadding(projects) {
            let width = this.$el.clientWidth;
            let height = this.$el.clientHeight;

            if (width === height) {
                return 250;
            }

            if (width < 420) {
                return 50;
            } else if (width < 600) {
                return 50;
            } else if (width < 920) {
                return 75;
            }

            return 150;
        },
        isSquare() {
            let width = this.$el.clientWidth;
            let height = this.$el.clientHeight;

            if (width === height) {
                return true
            }
            return false
        }
    }))
};
